import {PageFormat} from "../custom-datatypes/PageFormat";
import {GoldmannBrightness} from "./GoldmannBrightness";
import {StimulusSize} from "../custom-datatypes/StimulusSize";

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface DeviceSettings {
    main: Main;
    communication: Communication;
    additionalOutput: AdditionalOutput;
    display: Display;
    camera: Camera;
    examination: Examination;
    print: Print;
    spark: Spark;
    pdf: Pdf;
    centerfield: Centerfield;
    smartfield: Smartfield;
}

/**
 * Enum representing date formats used in the Main settings.
 *
 * Note:
 * This data type does not exist in the "Perimeter Device Backend REST-Protocol".
 * It is extracted from the description provided for "Main".
 */
export enum CombinedDateFormat {
    YMD = "yyyy-MM-dd",
    MDY = "MM-dd-yyyy",
    DMY = "dd-MM-yyyy",
    YMD_SLASH = "yyyy/MM/dd",
    MDY_SLASH = "MM/dd/yyyy",
    DMY_SLASH = "dd/MM/yyyy",
    YMD_DOT = "yyyy.MM.dd",
    MDY_DOT = "MM.dd.yyyy",
    DMY_DOT = "dd.MM.yyyy",
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Main {
    /**
     * Example values: `en-US`, `de-CH`, or `zh-TW`
     * @see https://doc.qt.io/qt-6/qlocale.html#bcp47Name
     */
    language: string;
    dateFormat: CombinedDateFormat;
    doctorsName: string;
    praxisName: string;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Communication {
    useSuspend: boolean;
    energySaverEnabled: boolean;
    /** An integer */
    energySaverTimeoutSec: number;
    /** An integer */
    usbWatchdogMs: number;
    /** An integer */
    usbWaitMicroseconds: number;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface AdditionalOutput {
    gdtPath: string;
    gdtActive: boolean;
    gdtBothEyes: boolean;
    /** An integer */
    gdtDetailLevel: number;
    csvPath: string;
    csvActive: boolean;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Display {
    /** An integer */
    evaluationBrightness: number;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Camera {
    /** Float [0-1] */
    brightness: number;
    mirrorX: boolean;
    autoContrast: boolean;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Examination {
    greyscaleRelative: boolean;
    enableSuprathresholdFov: boolean;
    applyFalseNegativeCheck: boolean;
    defaultIsopterBrightness: GoldmannBrightness;
    defaultIsopterStimulusSize: StimulusSize;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Print {
    useModernDesign: boolean;
    showGssGraph: boolean;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Spark {
    showGsp: boolean;
    enableFov: boolean;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Pdf {
    pageFormat: PageFormat;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Centerfield {
    chinrestEnabled: boolean;
}

/** See "Perimeter Device Backend REST-Protocol" for details */
export interface Smartfield {
    networkAddress: string;
}