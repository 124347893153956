import {Message} from "../interfaces/Message";

/**
 * Interface representing a mock server for WebSocket connections.
 * Provides the basic functionalities of sending, closing, and listening to messages.
 *
 * @see getMockserver
 */
export interface Mockserver extends SimpleWebSocket {
    error: () => void;
}

/**
 * Retrieves the active mock server instance if available.
 *
 * Only available if `wsUrl === "mockserver"`.
 *
 * @returns The active `Mockserver` instance or `null` if none is available.
 */
export function getMockserver(): Mockserver | null {
    return (window as Window & { mockserver?: Mockserver }).mockserver ?? null;
}

/** A simplified variant of the {@link WebSocket} designed for easier mocking. */
export interface SimpleWebSocket {
    addMessageListener: (listener: (message: Message) => void) => void;
    removeMessageListener: (listener: (message: Message) => void) => void;
    close: () => void;
    send: (message: Message) => void;
}

/**
 * Initializes a WebSocket with provided event listeners and connects to the specified URL.
 *
 * If the URL is "mockserver," a mock WebSocket is returned for testing purposes.
 *
 * @param url - The URL to which to connect. If "mockserver", a mock WebSocket is returned.
 * @param callbacks
 * @param callbacks.onOpen - Callback function to be called when the WebSocket connection is established
 * @param callbacks.onClose - Callback function to be called when the WebSocket connection is closed
 * @param callbacks.onError - Callback function to be called when a WebSocket error occurs
 * @returns The created SimpleWebSocket instance
 */
export function initWebSocket(
    url: string | "mockserver",
    callbacks?: {
        onOpen?: () => void
        onClose?: () => void,
        onError?: () => void,
    },
): SimpleWebSocket {
    const handleOpen = (event?: Event) => {
        logWs("open", event);
        callbacks?.onOpen?.();
    }
    const handleClose = (event?: CloseEvent) => {
        logWs("close", event);
        callbacks?.onClose?.();
    }
    const handleError = (event?: Event) => {
        logWs("error", event);
        callbacks?.onError?.();
    }
    const messageListeners = new Set<Parameters<SimpleWebSocket["addMessageListener"]>[0]>();
    const addMessageListener: SimpleWebSocket["addMessageListener"] = (listener) => {
        messageListeners.add(listener);
    };
    const removeMessageListener: SimpleWebSocket["removeMessageListener"] = (listener) => {
        messageListeners.delete(listener);
    };
    const handleMessage = (event: Pick<MessageEvent, "data">) => {
        let message: Message | null = null;
        try {
            message = JSON.parse(event.data) as Message;
        } catch {
        }
        if (!message || typeof message.type !== "string") {
            logWs("invalid message", event.data);
            return;
        }
        logWs("message", message.type === "CameraImage" ? JSON.stringify({...message, uri: "omitted"}) : event.data);
        messageListeners.forEach((listener) => listener(message));
    }
    if (url === "mockserver") {
        // Test environment
        const mockMessageListeners = new Set<Parameters<Mockserver["addMessageListener"]>[0]>();
        const mockSocket = {
            addMessageListener,
            removeMessageListener,
            send: (message) => setTimeout(() => {
                logWs("send", message);
                mockMessageListeners.forEach((listener) => listener(message));
            }),
            close: () => {
                messageListeners.clear();
                mockMessageListeners.clear();
                (window as Window & { mockserver?: Mockserver }).mockserver = undefined;
                setTimeout(() => handleClose());
            },
        } satisfies SimpleWebSocket;
        (window as Window & { mockserver?: Mockserver }).mockserver = {
            close: () => mockSocket.close(),
            error: () => setTimeout(() => handleError()),
            send: (message) => setTimeout(() => {
                const event = {
                    data: typeof message === "object" ? JSON.stringify(message) : message,
                };
                handleMessage(event);
            }),
            addMessageListener: (listener) => mockMessageListeners.add(listener),
            removeMessageListener: (listener) => mockMessageListeners.delete(listener),
        };
        setTimeout(() => handleOpen());
        return mockSocket;
    }

    const socket = new WebSocket(url);
    socket.addEventListener("open", handleOpen);
    socket.addEventListener("close", handleClose);
    socket.addEventListener("error", handleError);
    socket.addEventListener("message", handleMessage);
    return {
        addMessageListener,
        removeMessageListener,
        send: (message) => {
            logWs("send", message);
            socket.send(JSON.stringify(message));
        },
        close: () => {
            socket.close();
            messageListeners.clear();
        },
    };
}

/**
 * Helper function for logging WebSocket related events to the console.
 *
 * @param type - The category or nature of the event being logged
 * @param res - The event data or object associated with the type of event being logged
 */
function logWs(type: string, res: any) {
    console.info(`🔌 ${type}: `, res);
}
