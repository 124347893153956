import React, {useEffect} from 'react';
import {App, AppState} from '@capacitor/app'
import Cookies from "js-cookie";
import {navigate} from "gatsby";

/**
 * This component manages the state changes of the Capacitor application.
 *
 * - If no "token" cookie is set, it triggers a redirection to the patient management system.
 * - When the application is sent to the background, it deletes the "token" cookie
 *
 * @note
 * This seems only suitable for a mock server setup.
 *
 * @component
 */
const AppListener: React.FC = () => {
    useEffect(() => {
        if (process.env.GATSBY_ACTIVE_ENV === "capacitor") {
            void App.addListener('appStateChange', async (state: AppState) => {
                console.log("App Listener isActive: " + Cookies.get("token"))
                if (Cookies.get("token") === undefined) {
                    console.log("App Listener couldn't find a token ... redirect to login")
                    await navigate(process.env.GATSBY_PATIENT_ADMINISTRATION_URL ?? "http://localhost:8000")
                }
                if (!state.isActive) {
                    console.log("Remove Cookies")
                    Cookies.remove("token")
                }
            });
        }
    }, []);

    return null;
};

export default AppListener;