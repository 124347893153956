/**
 * @module ErrorScreen
 * This module provides components and functions for global error handling in the React application.
 *
 * The main components and functions exported by this module include:
 * - `activateErrorScreen`: A function to activate the error screen.
 * - `ErrorScreen`: A React component that displays an error screen when an error occurs.
 *
 * Example usage:
 *
 * ```tsx
 * // Import the components and functions
 * import ErrorScreen, { activateErrorScreen } from "./ErrorScreen";
 *
 * // Use the ErrorScreen component at the root of the application
 * function App() {
 *     return (
 *         <ErrorScreen>
 *             <YourAppComponents />
 *         </ErrorScreen>
 *     );
 * }
 *
 * // Handle fatal errors using activateErrorScreen
 * try {
 *     // Your code that might throw an error
 * } catch (err) {
 *     activateErrorScreen(err);
 *     return;
 * }
 *
 * // Handle non-fatal errors using activateErrorScreen
 * try {
 *     // Your code that might throw an error
 * } catch (err) {
 *     activateErrorScreen(err, false); // Non-fatal error
 *     return;
 * }
 * ```
 *
 * The `ErrorScreen` component will automatically catch errors from its children components using the `ErrorBoundary` component,
 * and it will display a dialog with the error message if an error is encountered.
 */

import {MaterialSymbol} from "react-material-symbols";
import React, {Component, PropsWithChildren, useCallback, useState} from "react";
import {Dialog as HeadlessuiDialog} from "@headlessui/react"
import {errorToString} from "../helper";
import {newStore, useStore} from "@oculus/component-library";

/** `errorStore` state */
interface ErrorStoreState {
    /** Array of errors */
    errors: Error[];

    /** Indicates if the error is fatal, meaning the error screen cannot be closed. */
    fatal: boolean;
}

/** Error store for globally managing application errors. */
const errorStore = newStore<ErrorStoreState>({errors: [], fatal: false})

/**
 * Activates the error screen.
 *
 * Note: A fatal error means the error screen cannot be closed.
 *
 * @param err - The error to be handled.
 * @param fatal - Boolean indicating if the error is fatal. Default is `true`.
 */
export function activateErrorScreen(err: Error, fatal: boolean = true): void {
    console.error(err);
    errorStore.set((state) => ({
        errors: [...state.errors, err],
        fatal: state.fatal || fatal,
    }));
}

/** `ErrorBoundary` component properties. */
interface ErrorBoundaryState {
    /** Indicates if an error was encountered. */
    fatal: boolean;
}

/**
 * Error boundary component for catching errors in React components.
 *
 * Calls {@link activateErrorScreen} when an error was caught.
 *
 * @component
 */
class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
    state: ErrorBoundaryState = {fatal: false};

    /**
     * Recovers the component with a new state when an error is caught.
     *
     * @return The new state
     */
    static getDerivedStateFromError() {
        return {fatal: true};
    }

    /**
     * Lifecycle method that gets called when an error is caught.
     *
     * @param err - The error that was caught.
     */
    componentDidCatch(err: Error) {
        activateErrorScreen(err, this.state.fatal);
    }

    render() {
        if (this.state.fatal) {
            return null;
        }
        return this.props.children;
    }
}

/**
 * Component for displaying an error screen when a fatal error occurs.
 *
 * @component
 * {@link PropsWithChildren}
 *
 * @see activateErrorScreen
 */
const ErrorScreen: React.FC<PropsWithChildren> = ({children}) => {
    const [{errors, fatal}, setState] = useStore(errorStore);
    const [errorElement, setErrorElement] = useState<HTMLElement | null>(null);
    const handleClose = useCallback(() => setState(
        (state) => state.fatal ? state : ({...state, errors: []})
    ), [setState]);
    return (<>
        {!fatal ? <ErrorBoundary>{children}</ErrorBoundary> : null}
        <HeadlessuiDialog
            as="div" open={errors.length > 0 || fatal}
            onClose={handleClose}
            className="fixed inset-0 overflow-auto z-[1000] bg-darkBlue80 text-white text-[25px] font-regular select-none [scroll-snap-type:y_mandatory]">
            {!fatal ? (
                <button onClick={handleClose} className="cursor-pointer fixed right-12 top-6">
                    <MaterialSymbol icon={"close"} size={50} as="div"/>
                </button>
            ) : null}
            <div className="min-h-full w-full flex flex-col items-center justify-center [scroll-snap-align:start]">
                <div className="max-w-400 w-full flex flex-col items-center gap-6 p-6">
                    <MaterialSymbol icon={"sentiment_very_dissatisfied"} size={250} as="div"/>
                    <p className="text-[50px]">
                        Irgendetwas ist schief gelaufen
                    </p>
                    <button className="cursor-pointer flex flex-row items-end group"
                            onClick={() => errorElement?.scrollIntoView({behavior: "smooth"})}>
                        <MaterialSymbol icon={"keyboard_double_arrow_down"} as="span"
                                        className="animate-bounce opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 transition-opacity"/>
                        <span>Fehlermeldung anzeigen</span>
                        <MaterialSymbol icon={"keyboard_double_arrow_down"} as="span"
                                        className="animate-bounce opacity-0 group-hover:opacity-100 group-focus-visible:opacity-100 transition-opacity"/>
                    </button>
                </div>
            </div>
            <div className="min-h-full w-full max-w-400 mx-auto p-6 [scroll-snap-align:start] space-y-12"
                 ref={setErrorElement}
            >
                {errors.map((err, index) => (
                    <div key={index} className="select-text">
                        <div className="text-[50px]">{errorToString(err)}</div>
                        {typeof err === "object" && typeof err?.stack === "string" && err.stack ? <div className="whitespace-pre">{`${err.stack}`}</div> : null}
                    </div>
                ))}
            </div>
        </HeadlessuiDialog>
    </>);
};

export default ErrorScreen;