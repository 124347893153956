class AsyncLock {
    private waiters: (() => void)[] = [];
    private locked: boolean = false;

    /**
     * Tries to acquire the lock.
     * If the lock is held by another caller, it will wait until the lock is released.
     */
    async acquire(): Promise<void> {
        if (this.locked) {
            await new Promise<void>((resolve) => {
                this.waiters.push(resolve);
            });
        }
        this.locked = true;
    }

    /**
     * Releases the lock.
     *
     * @throws {Error} If the lock is not acquired.
     */
    release(): void {
        if (!this.locked) {
            throw new Error("AsyncLock not acquired");
        }
        this.locked = false;
        this.waiters.pop()?.();
    }

    /**
     * Checks if the lock is currently acquired.
     *
     * @returns True if the lock is acquired, false otherwise.
     */
    isLocked(): boolean {
        return this.locked;
    }
}

export default AsyncLock;